/* Importa a fonte Roboto com peso 300 e 500 */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');


body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400; 
}


strong {
  font-weight: 500; 
}

.categoria-card {
  margin-bottom: 20px; 
}

.custom-link {
  color: #ec2300;
  /* Set your desired color */
  text-decoration: none;
  /* Remove underline */
  cursor: pointer;
  /* Set cursor to pointer */
}


.small-form {
  font-size: 13px;
}


.small-form {
  font-size: 13px; 
}

.Form.Control.Feedback {
  color: red;
  font-size: 13px;
  visibility: visible;
}


.capa-imagem {
  width: 1300px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px,
}

.avatar-imagem {
  position: absolute;
  top: 100%;
  left: 18%;
  transform: translate(-50%, -50%);
  width: 15%;
  height: auto;
  max-width: 50%;
  border: 3px solid white;
  box-sizing: border-box;
}

  /* Estilos para smartphones */
  @media (max-width: 768px) {
    .capa-imagem {
      width: 768px;
      height: 125px;
      object-fit: cover;
      border-radius: 10px,
    }

    .avatar-imagem {
      position: absolute;
      top: 95%;
      left: 23%;
      transform: translate(-50%, -50%);
      width: 25%;
      height: auto;
      max-width: 70%;
      border: 3px solid white;
      box-sizing: border-box;
    }
  }

  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
  }

  .capa-imagem {
    border-radius: 10px;
    /* ou o valor desejado para arredondar as bordas */
  }

  .small-font {
    font-size: 12px; // ou o tamanho desejado
  }

  
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
 }
 
 .carousel-control-next-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
 }
 
 
/* Adicione este CSS ao seu arquivo de estilo ou componente */
.carousel-control-prev,
.carousel-control-next {
  width: 5%; /* Ajuste a largura conforme necessário */
  color: #ff0000; /* Substitua #ff0000 pelo código da cor desejada */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px; /* Ajuste a largura do ícone da seta */
  height: 30px; /* Ajuste a altura do ícone da seta */
  background-size: 100%, 100%;
  color: #ff0000; /* Substitua #ff0000 pelo código da cor desejada */
  border-radius: 50%; /* Adicione bordas redondas */
  background-color: #808080; /* Substitua #808080 pelo código da cor de fundo desejada */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adicione uma sombra para simular uma borda */
}

/* Opcionalmente, você pode ajustar a posição das setas */
.carousel-control-prev,
.carousel-control-next {
  top: 50%; /* Ajuste a posição vertical para o centro */
  transform: translateY(-50%);
}

.carousel-control-prev {
  left: 0; /* Ajuste a distância da borda esquerda */
}

.carousel-control-next {
  right: 0; /* Ajuste a distância da borda direita */
}

.gradient-background {
  background: linear-gradient(to bottom, #dae0e6, transparent);
  min-height: 100vh; /* Mínimo de 100% da altura da viewport */
  height: auto; /* Altura se ajustará ao conteúdo do contêiner */
}


.rounded-image {
  border-radius: 10px; /* Defina o raio desejado para o arredondamento das bordas */
}

/* styles.css */
.gray-light-bg {
  background-color: #f5f5f5; /* ou qualquer outra cor cinza claro desejada */
}

.blue-light-bg {
  background-color: #add8e6; /* azul claro */
}

.green-light-bg {
  background-color: #c8e6c9; /* verde claro */
  
}

.yellow-light-bg {
  background-color: #fff9c4; /* amarelo claro */
}

.red-light-bg {
  background-color: #fd364a; /* vermelho claro */
  color: #fff; /* Cor do texto, ajuste conforme necessário */
}

.black-light-bg {
  background-color: #333; /* Ou a cor desejada para status específico */
  color: #fff; /* Cor do texto, ajuste conforme necessário */
}

.custom-card {
  transition: transform 0.3s ease;
}

.custom-card:hover {
  transform: translateY(-8px);
}

.navbar .nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.navbar .nav-link:hover {
  color: #007bff;
}
